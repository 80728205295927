import { BASE_URL } from '../../config';

import {
    FORCE_UPDATE_ORDER,
    UPDATE_ORDERS,
    GET_ALL_ORDERS,
    ADD_ORDER,
    UPDATE_PRODUCT,
    SELECT_ORDER,
    UPDATE_ORDER,
    ORDER_FINISHED
} from './order.actionsTypes';

import axios from 'axios';

import delivery from '../../helpers/delivery/delivery';
import Notifier from '../../helpers/message/Notifier';
import {
    CONNECTION_ERRORS
} from '../../config/constants';


export const addOrder = (order) => {
    return {
        type: ADD_ORDER,
        order
    };
};

const registerSessionListeners = (dispatch) => {
    if (delivery.ordersListenersRegistered) {
        return;
    }
    let orders = [];
    delivery.orderAdded((order) => {
        console.log("orderAdded---->",order)
        dispatch(getOrder(order, addOrder));
    });
    delivery.orderUpdated((order) => {
        console.log("orderUpdated---->",order)
        if (order.forceUpdate) {
            console.log("forceUpdate---->",order.forceUpdate)
            dispatch(getOrder(order, forceUpdateOrder));
        }
        orders.push(order);
    });
    delivery.disconnected((reason) => {
        if (-1 === CONNECTION_ERRORS.forced_close.reason.indexOf(reason)) {
            Notifier.error(CONNECTION_ERRORS.network_error.message, CONNECTION_ERRORS.network_error.title, true);
        }
    })
    delivery.orderFinished( (order) => {
        dispatch(orderFinished(order));
    });
    setInterval(() => {
        if (orders.length) {
            dispatch(updateOrders(orders));
            orders = [];
        }
    }, 5000);
    delivery.ordersListenersRegistered = true;
};

export const updateOrders = (orders) => {
    return {
        type: UPDATE_ORDERS,
        orders
    };
};

export const orderFinished = (order) => {
    return {
        type: ORDER_FINISHED,
        order
    };
};

export const updateOrder = (order) => {
    return {
        type: UPDATE_ORDER,
        order
    };
};

export const  updateProduct = (product, orderId) => {
    return {
        type: UPDATE_PRODUCT,
        product,
        orderId
    };
}

export const updateProductByApi = (orderId, product) => {
    return (dispatch) => {
        axios.put(`${BASE_URL}order/discuss/${orderId}/${product._id}`, product)
            .then((response) => {
                if (response.data) {
                    dispatch(updateProduct(response.data, orderId));
                    Notifier.success('_product_successfully_updated_', '_product_updated_');
                }
            });
    };
};

export const getOrder = (order, callback) => {
    return (dispatch) => {
        axios.get(`${BASE_URL}order/supplier/${order.orderId}`)
            .then((response) => {
                if (response.data) {
                    dispatch(callback(response.data));
                }
            });
    };
};

export const forceUpdateOrder = (order) => {
    return {
        type: FORCE_UPDATE_ORDER,
        order,
    };
};

export const getAllOrders = (cb) => {
    return (dispatch) => {
        axios.get(`${BASE_URL}order/supplier`)
            .then((response) => {
                registerSessionListeners(dispatch);
                if (response.data) {
                    dispatch({
                        type: GET_ALL_ORDERS,
                        orders: response.data
                    });
                }
                if('function' === typeof cb) {
                    cb(response);
                }
            });
    };
};

export const selectOrder = (order) => {
    return {
        type: SELECT_ORDER,
        order
    };
};

export const readyToDelivery = (orderId, data, cb) => {
    return (dispatch) => {
        axios.put(`${BASE_URL}order/readyToDelivery/${orderId}/`, data)
            .then((response) => {
                if (response.data) {
                    dispatch(updateOrder(response.data));
                    Notifier.success('_product_successfully_updated_', '_product_updated_');
                    cb(response.data);
                }
            });
    };
};

export const inCollecting = (orderId, inCollecting) => {
    return (dispatch) => {
        axios.put(`${BASE_URL}order/inCollecting/${orderId}/${inCollecting}/`)
            .then((response) => {
                if (response.data) {
                    dispatch(updateOrder(response.data));
                    Notifier.success('_product_successfully_updated_', '_product_updated_');
                }
            });
    };
};

export const withIce = (orderId, withIce) => {
    return (dispatch) => {
        axios.put(`${BASE_URL}order/withIce/${orderId}/${withIce}/`)
            .then((response) => {
                if (response.data) {
                    dispatch(updateOrder(response.data));
                    Notifier.success('_product_successfully_updated_', '_product_updated_');
                }
            });
    };
};
