import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Container,
	Row,
	Col,
	Card
} from 'react-bootstrap';
import {
	selectOrder
} from '../orders.actions';
import translator from '../../../helpers/localization/translator';
import { convertEtaToHours } from '../../../helpers/utils/';
import { SUPPLIER_STATUSES, SUPPLIER_STATES, MIN_ETA } from '../../../config/constants';

class Orders extends Component {

	navigateToOrder = (id, order) => () => {
		this.props.selectOrder(order)
		this.props.history.push(`/order/${id}`);
	}

	renderETAs(order) {
		let etaColor = order.eta <= MIN_ETA.SUPPLIER ? 'bg-danger' : 'bg-warning';
		return (
			<div className="d-flex flex-wrap justify-content-center">
				<div className={`${etaColor} text-white mr-3`}>
					<span className="p-1">{translator.translate('_packaging_time_')}՝</span>
					<span className="p-1">{convertEtaToHours(order.eta)}</span>
				</div>
			</div>
		);
	}

	renderRow(order) {
		return (
			<div className="d-flex flex-wrap justify-content-center">
				{<div className="mr-3">{order.distributor}</div>}
				{order.isPromotionOrder ? <div className="mr-3"><b><u>{order.orderId}</u></b></div> : <div className="mr-3"><b>{order.orderId}</b></div>}
				{order.status !== SUPPLIER_STATUSES.PICKED_UP.statusId ? this.renderETAs(order) : null}
				{order.inCollecting && order.status !== SUPPLIER_STATUSES.PICKED_UP.statusId ?
					<div className="bg-primary text-white mr-3"><span className='p-1'>{translator.translate('_collecting_')}</span></div> : null}
			</div>
		);
	}

	render() {
		return (<Container fluid className="bg-light">
			<Row className='page-content d-flex mt-5'>
				<Col xs={12} className='mt-5 d-flex justify-content-center'>
					{translator.translate('_orders_')}
				</Col>
			</Row>
			{this.props.orders.map((order) => {
				let rowClass = '';
				if (order.state === SUPPLIER_STATES.READY_TO_DELIVERY.stateId) {
					rowClass = 'bg-success';
				} else if (order.state === SUPPLIER_STATES.SENDED.stateId) {
					rowClass = 'bg-info';
				}
				return (<Row key={order._id} onClick={this.navigateToOrder(order.orderId, order)}>
					<Col xs={12} className='mt-3 p-0 d-flex justify-content-center c-pointer'>
						<Card body className={`${rowClass} w-100 text-center border-right-0 border-top-0
							border-bottom-2	rounded-0 shadow border-left-0 font-weight-bolder`}>
							{this.renderRow(order)}
						</Card>
					</Col>
				</Row>);
			})}
		</Container>);
	}
};

let mapStateToProps = (state) => {
	return {
		orders: state.orders
	};
};

export default connect(mapStateToProps, { selectOrder })(Orders);
