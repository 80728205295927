import React, { Component } from 'react';
import { connect } from 'react-redux';

import translator from '../../localization/translator';

import './message.css';
class MessageBody extends Component {
	render() {
		return (
			<div className="custom-message">
				<p className="message-text">
					{translator.translate(this.props.message)}
				</p>
			</div>
		);
	}
};

export default connect()(MessageBody);