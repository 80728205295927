import React, {Component} from 'react';
import {
    Redirect
} from 'react-router-dom';

import security from '../helpers/security/security';

export const Cms = (props) => {
    if (security.isAuthorized()) {
        return <Redirect to='/order' />;
    }
    return <Redirect to='/login' />;
};

export const withPermissionControl = (WrappedComponent, options) => {
    return class withPermissionControl extends Component {
        isRoleAllowed(roles) {
            return -1 !== roles.indexOf(security.getUser().role);
        }
        render() {
            const {allowedRoles} = options;
            const isAuthorized = security.isAuthorized();
            if(options.allowAuthorized && !isAuthorized){
                return <Redirect to='/login' />;
            }
            if(options.allowUnauthorized && isAuthorized){
                return <Redirect to={options.home} />;
            }
            if(allowedRoles && (!isAuthorized || !this.isRoleAllowed(allowedRoles))) {
                return <Redirect to={options.home} />;
            }
            return <WrappedComponent {...this.props} />;
        }
    }
};