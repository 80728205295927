import {
    SELECT_ORDER,
    FORCE_UPDATE_ORDER,
    UPDATE_ORDER,
    UPDATE_ORDERS,
    ORDER_FINISHED
} from './order.actionsTypes';

export default function orderDetails(state = {}, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case SELECT_ORDER:
            return {...action.order};
            case FORCE_UPDATE_ORDER:
                    if(newState.orderId === action.order.orderId){
                        return {...action.order};
                    }
                    return newState;
            case UPDATE_ORDER:
                if(newState.orderId === action.order.orderId){
                    newState.products = action.order.products;
                    newState.status = action.order.status;
                    newState.state = action.order.state;
                }
                return newState;

            case UPDATE_ORDERS:
                action.orders.forEach(order => {
                    if(order.orderId === newState.orderId) {
                        newState.eta = order.eta;
                        newState.state = order.state;
                        newState.status = order.status;
                    }
                });
                return newState;

            case ORDER_FINISHED:
                if(newState.orderId === action.order.orderId){
                    newState = {};
                }
                return newState;
        default:
            return newState;
    }
}
