const unregisterServiceWorker = () => {
    try{
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations()
            .then(registrations => {
                for (let register in registrations){
                    registrations[register].unregister();
                }
            })
               
        }
    } catch (error) {
        console.error(error);
    }
}
export {
    unregisterServiceWorker
}