import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { Provider } from 'react-redux';
import { loadTranslations, setLocale, syncTranslationWithStore} from 'react-redux-i18n';

import store from './config/store';
import { LANG } from './config';
import './config/middlewares';

import localization from './helpers/localization/localization';

// import App from './App';
import security from './helpers/security/security';
import jwtHelper from './helpers/jwt/jwtHelper';
import delivery from './helpers/delivery/delivery';
import './index.css';
import App from './App';

security.configure(store);
jwtHelper.configure(store);
delivery.start();

syncTranslationWithStore(store);
store.dispatch(loadTranslations(localization));
store.dispatch(setLocale(LANG));

ReactDOM.render(
    <Provider store={store}>
        <App />
     </Provider>
    , document.getElementById('root')
);