import React, {	Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

class Message extends Component {

	render() {
		return (
			<div id='custom-redux-toastr'>
				<ReduxToastr
					timeOut={this.props.timeOut}
					newestOnTop={this.props.newestOnTop}
					preventDuplicates={true}
					position={this.props.position}
					transitionIn={this.props.transitionIn}
					transitionOut={this.props.transitionOut}
					progressBar />
			</div>
		);
	}
};

Message.defaultProps = {
	timeOut: 5000,
	newestOnTop: true,
	preventDuplicates: true,
	position: 'top-right',
	transitionIn: 'fadeIn',
	transitionOut: 'fadeOut'
};

Message.propTypes = {
	timeOut: PropTypes.number,
	newestOnTop: PropTypes.bool,
	preventDuplicates: PropTypes.bool,
	position: PropTypes.string,
	transitionIn: PropTypes.string,
	transitionOut: PropTypes.string
};

export default connect()(Message);
