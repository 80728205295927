import {
    FORCE_UPDATE_ORDER,
    GET_ALL_ORDERS,
    UPDATE_PRODUCT,
    UPDATE_ORDERS,
    UPDATE_ORDER,
    ADD_ORDER,
    ORDER_FINISHED
} from './order.actionsTypes';

import { SUPPLIER_STATUSES, SUPPLIER_STATES } from '../../config/constants';

const finalizeOrders = (orders) => {
    const statuses = [
        SUPPLIER_STATUSES.VERIFIED.statusId,
        SUPPLIER_STATUSES.DELIVERED.statusId
    ];
    // return orders.filter(order => !statuses.includes(order.status))
    // .sort(order => order.state - SUPPLIER_STATES.READY_TO_DELIVERY.stateId);
    return orders.filter(order => !statuses.includes(order.status))
        .sort((a, b) => !a.distributor ? 1 : (!b.distributor ? -1 : a.distributor.localeCompare(b.distributor)));
};

export default function orders(state = [], action) {
    let newState = Object.assign([], state);
    const isStateIncludesOrder = newState.some(order => (
        order &&
        order.orderId &&
        action.order &&
        action.order.orderId &&
        order.orderId === action.order.orderId)
    );
    switch (action.type) {
        case GET_ALL_ORDERS:
            return finalizeOrders([...action.orders]);
        case ADD_ORDER:
            if (!isStateIncludesOrder) {
                newState.push(action.order);
            }
            return finalizeOrders(newState);
        case FORCE_UPDATE_ORDER:
            if (action.order.removeOrder) {
                return finalizeOrders(newState.filter(order => (order.orderId != action.order.orderId)));
            }
            if (isStateIncludesOrder) {
                return finalizeOrders(newState.map(order => {
                    if (order.orderId === action.order.orderId) {
                        return action.order;
                    }
                    return order;
                }));
            } else {
                newState.push(action.order);
                return finalizeOrders(newState);
            }
        case UPDATE_ORDERS:
            return finalizeOrders(newState.map(order => {
                action.orders.forEach(update => {
                    if (update.orderId === order.orderId) {
                        order.eta = update.eta;
                        order.state = update.state;
                        order.status = update.status;
                    }
                });
                return order;
            }));
        case UPDATE_ORDER:
            const update = action.order;
            return finalizeOrders(newState.map(order => {
                if (update.orderId === order.orderId) {
                    return update;
                }
                return order;
            }));
        case ORDER_FINISHED:
            return finalizeOrders(newState.filter(order => action.order.orderId !== order.orderId));
        case UPDATE_PRODUCT:
            const order = newState.find(order => order._id === action.orderId);
            if (order) {
                order.products = order.products.map(product => {
                    if (product._id === action.product._id) {
                        product = { ...product, ...action.product };
                    }
                    return product;
                });
            }
            return finalizeOrders(newState);
        default:
            return newState;
    }
}
