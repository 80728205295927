import { I18n } from 'react-redux-i18n';

class Тranslator {
	translate(key) {
		return  I18n.t(key) || key;
	}
}

const translator =  new Тranslator();

export default translator;