import React from 'react';
import { toastr } from 'react-redux-toastr';

import MessageBody from './template/MessageBody';
import translator from '../localization/translator';


let collectBody = (message, disableAutoClose) => {
	const toastrOptions = {
		showCloseButton: disableAutoClose ? true : false,
		component: (
			<MessageBody message={message} />
		)
	};
	if (disableAutoClose) {
		toastrOptions.timeOut = 0;
		toastrOptions.removeOnHover = false;
	}
	return toastrOptions;
};

export default class Notifier {
	static warning(message, title='', disableAutoClose) {
		toastr.warning(translator.translate(title), '', collectBody(message, disableAutoClose));
	}
	static success(message, title='', disableAutoClose) {
		toastr.success(translator.translate(title), '', collectBody(message, disableAutoClose));
	}
	static error(message, title='', disableAutoClose) {
		toastr.error(translator.translate(title), '', collectBody(message, disableAutoClose));
	}
	static info(message, title='', disableAutoClose) {
		toastr.info(translator.translate(title), '', collectBody(message, disableAutoClose));
	}
}