import React, { Component } from 'react';
import {
	Button,
    Form,
    Row,
    Col,
	Modal
} from 'react-bootstrap';
import translator from '../../../helpers/localization/translator';

class ProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onChange = (key) => (e) => {
        this.setState({[key]: e.target.value});
    }

    getValue = (key, defaultKey = '') => {
        if(this.state.hasOwnProperty(key)) {
            return this.state[key] || '';
        }
        return this.props.product[key] || this.props.product[defaultKey] || '';
    }

    onHide = () =>{
        this.setState({});
        if('function' === typeof this.props.onHide){
            this.props.onHide();
        }
    }

    onSave = () => {
        if('function' === typeof this.props.onSave){
            const { _id } = this.props.product;
            this.props.onSave({_id, ...this.state});
        }
    }

	render() {
        const { show, onCancel, product} = this.props;
		return (<Modal show={show} onHide={this.onHide} size="sm" centered>
            <Modal.Header closeButton className="bg-info">
                <Modal.Title>
                    {translator.translate("_edit_")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='page-content'>
                    <Col xs={8} className='d-flex'>
                        {product.name}
                    </Col>
                    <Col xs={4} className='d-flex justify-content-end bg-white'>
                        {product.code}
                    </Col>
                </Row>
                <Row className='page-content d-flex'>
                    <Col xs={12} className='mt-3'>
                        <Form.Label>
                            {translator.translate('_quantity_')}
                        </Form.Label>
                        <Form.Control type="number" onChange={this.onChange('partnerQuantity')}
                            value={this.getValue('partnerQuantity', 'quantity')}
                            placeholder={translator.translate('_quantity_')}/>
                    </Col>
                </Row>
                <Row className='page-content d-flex'>
                    <Col xs={12} className='mt-3'>
                        <Form.Label>{translator.translate('_comments_')}</Form.Label>
                        <Form.Control as="textarea" rows="5" onChange={this.onChange('message')}/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button className="btn-buy-primary" onClick={onCancel}>
                    {translator.translate("_cancel_")}
                </Button>
                <Button className="btn-buy-main" onClick={this.onSave}>
                    {translator.translate("_save_")}
                </Button>
            </Modal.Footer>
        </Modal>);
	}
};

export default ProductModal;
