import {
    createStore,
    applyMiddleware
} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';
import securityMiddleware  from '../helpers/security/security.middleware';
let store = createStore(reducers, applyMiddleware(ReduxThunk, securityMiddleware));

export default store;
