import jwtHelper from '../jwt/jwtHelper';


class Security {
    configure(store, types) {
        if (store) {
            this.store = store;
        }
    }

    isAuthorized() {
        const token = jwtHelper.getToken();
        return token && !jwtHelper.isTokenExpired(token);
    }

    login(token) {
        jwtHelper.setToken(token);
    }

    logout() {
        jwtHelper.deleteToken();
    }

    getToken() {
        return jwtHelper.getToken();
    }

    getUser() {
        const token = jwtHelper.getToken();
        let user = {};
        if (token&& this.isAuthorized()) {
            user = jwtHelper.decodeToken(token);
        }
        return user;
    }
}

const security = new Security();

export default security;
