import React, { Component } from 'react';
import  {
	Container,
	Row,
	Col,
	Form,
	Button,
	Image
} from 'react-bootstrap';

import translator from '../../../helpers/localization/translator';
import { connect } from 'react-redux';
import { login } from '../login.actions';
import { version } from '../../../../package.json'
import logo from '../../../assets/img/logo.png';

class LoginForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.forceUpdate();
		}, 1);
	}

	auth = (event) => {
		event.preventDefault();
		event.stopPropagation();
		let username = this.state.username;
		let password = this.state.password;
		this.props.login({ username, password }, () => {
			this.props.history.push('/order');
		});
	}

	renderInput(type, placeholder, name) {
		return (
			<Form.Group>
				<Form.Control
					type={type}
					placeholder={translator.translate(placeholder)}
					name={name}
					value={this.state[name]}
					autoComplete={name}
					onChange={(e) => {
						this.setState({ [name]: e.target.value });
					}} />
				<Form.Control.Feedback />
			</Form.Group>
		);
	}

	render() {
		let isDisabled = true,
			autoFill = false;
		try {
			autoFill = document.querySelectorAll('input:-webkit-autofill').length === 2;
		} catch(e) { }
		if (autoFill || (this.state.username !== '' && this.state.password !== '')) {
			isDisabled = false;
		} else {
			isDisabled = true;
		}
		return (
			<Container>
				<Row className="justify-content-center">
					<Col xs={12} sm={7} md={6} lg={4} className="login-container mt-5">
						<Row>
							<Col xs={12} className="d-flex justify-content-center mt-5">
								<Image src={logo} className="h-75 w-25"/>
							</Col>
						</Row>
						<form method='post' onSubmit={this.auth}>
							{this.renderInput('text', '_username_', 'username')}
							{this.renderInput('password', '_password_', 'password')}
							<Form.Group>
								<Form.Check type="checkbox" label={translator.translate("_remember_me_")} />
							</Form.Group>
							<Form.Group>
								<Button
									type="btn"
									className="btn-buy-primary"
									onClick={this.auth}
									block
									disabled={isDisabled}>
									{translator.translate("_login_")}
								</Button>
							</Form.Group>
							<span>
								{`${translator.translate('_version_')}: v${version}`}
							</span>
						</form>
					</Col>
				</Row>
			</Container>
		);
	}
};

export default connect(null, {
	login
})(LoginForm);
