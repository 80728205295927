import { Component } from 'react';

import { connect } from 'react-redux';
import { getAllOrders } from '../Order/orders.actions';
import { registerSupplierToken } from '../Login/login.actions';
import security from '../../helpers/security/security';
import { getNotificationToken } from '../../helpers/firebase/messaging';
import { start, end } from './dataLoader.actions';

class DataLoader extends Component {
	constructor(props) {
		super(props);
		this.initialized = false;
	}

	loadData = (props) => {
		if(!this.initialized && (props.login.isAuthorized || security.isAuthorized())) {
			this.props.start();
			this.props.getAllOrders(() => {
				this.props.end();
			});
			getNotificationToken().then((token) => {
				if (token) {
					this.props.registerSupplierToken(token);
				}
			});
			this.initialized = true;
		}
	}

	componentWillReceiveProps(nextProps) {
		this.loadData(nextProps);
	}

	componentWillMount() {
		this.loadData(this.props);
	}

	render() {
		return null;
	}
};

let mapStateToProps = (state) => {
	return {
		login: state.login
	};
};


export default connect(mapStateToProps, { getAllOrders, registerSupplierToken, start, end })(DataLoader);
