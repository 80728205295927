import { START_DATA_LOADING, END_DATA_LOADING } from './dataLoader.actionsTypes';

export default function orders(state = {}, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case START_DATA_LOADING:
            return { ...newState, loading: true, loadingEnd: true};
        case END_DATA_LOADING:
            return { ...newState, loading: false, loadingEnd: true};
        default:
            return newState;
    }
}
