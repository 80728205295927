import { START_DATA_LOADING, END_DATA_LOADING } from './dataLoader.actionsTypes';

export const start = () => {
    return {
        type: START_DATA_LOADING
    };
};

export const end = () => {
    return {
        type: END_DATA_LOADING
    };
};
