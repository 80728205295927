import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging"

import Notifier from '../message/Notifier';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMIAN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebase = initializeApp(config);


const registerService = async () => {
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
        updateViaCache: 'none'
    });
    return registration;
    // messaging.useServiceWorker(registration)
}

const getNotificationToken = async () => {
    try {
        const messaging = getMessaging(firebase)

        const token = await getToken(messaging,{vapidKey: process.env.REACT_APP_VAPID_KEY}) //await messaging.getToken();
        console.log(token)
        onMessage(messaging,(payload) => {
            const { data } = payload;
            Notifier.info( data.body, data.title, true);
        })
        return token;
    } catch (error) {
        console.error(error);
    }
}

export {
    registerService,
    getNotificationToken
};