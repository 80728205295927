import io from 'socket.io-client';
import security from '../security/security';

const defaultOptions = {
    'transports': ['websocket'],
    'rejectUnauthorized': false,
    'secure': true,
    'verify': false
};

const authHandler = (session, data) => {
	session.isAuthenticated = data.authenticate;
};

const joinHandler = (session, order, data) => {
	if(data.joined) {
		session.rooms.push(order);
	}
};

class Session {
    constructor(url, id, options = defaultOptions) {
        this.id = id;
        this.url = url;
        this.options = options;
        this.isAuthenticated = false;
        this.rooms = [];
    }

    on(eventName, callback) {
        if ('string' !== typeof eventName) {
            throw new Error('eventName should be a string');
        }
        if ('function' !== typeof callback) {
            throw new Error('callback should be a function');
        }
        this.socket.on(eventName, callback);
    }

    once(eventName, callback) {
        if ('string' !== typeof eventName) {
            throw new Error('eventName should be a string');
        }
        if ('function' !== typeof callback) {
            throw new Error('callback should be a function');
        }
        this.socket.once(eventName, callback);
    }

    emit(eventName, data = {}) {
        if ('string' !== typeof eventName) {
            throw new Error('eventName should be a string');
        }
        this.socket.emit(eventName, data);
    }

    disconnect() {
        if (this.socket) {
            this.socket.io.disconnect();
            this.socket = null;
        }
    }

    connect(callback) {
        this.socket = io.connect(this.url, this.options);
        if ('function' === typeof callback) {
            this.on('connected', callback);
        }
    }

    authenticate(token = security.getToken(), callback) {
        this.emit('client:authenticate', { token: token });
        if ('function' === typeof callback) {
            this.once('authenticated', callback);
        }
        this.once('authenticated', authHandler.bind({}, this));
    }

    isJoined(order) {
    	return -1 !== this.rooms.indexOf(order);
    }

    join(order, callback) {
        this.emit('portalUser:join', { order: order , sessionId: this.id});
        if ('function' === typeof callback) {
            this.once('portalUser:joined', callback);
        }
        this.once('portalUser:joined', joinHandler.bind({}, this, order));
    }
}


export default Session;
