export const LOGIN_ACTION_TYPES = {
    LOGIN_SUCCESS: 'login_success',
    LOGOUT_SUCCESS: 'logout_success'
};

export const CONNECTION_ERRORS = {
    network_error: {
        title: '_server_disconnected_',
        message: '_refresh_page_',
        error: 'Network Error',
    },
    forced_close: {
        reason: ['forced close', 'io server disconnect'],
    }
};

export const SUPPLIER_STATUSES = {
    ASSIGNED: {
        statusId: 0,
        name: '_assigned_',
        className: 'warning'
    },
    ACCEPTED: {
        statusId: 1,
        name: '_accepted_',
        className: 'success'
    },
    CONFIGURED: {
        statusId: 2,
        name: '_configured_',
        className: 'success'
    },
    ON_WAY: {
        statusId: 3,
        name: '_on_way_',
        className: 'info'
    },
    PICKED_UP: {
        statusId: 4,
        name: '_picked_up_',
        className: 'info'
    },
    DELIVERED: {
        statusId: 5,
        name: '_delivered_',
        className: 'danger'
    },
    VERIFIED: {
        statusId: 6,
        name: '_verified_',
        className: 'danger'
    },
    AT_SUPPLIER: {
        statusId: 7,
        name: '_at_supplier_',
        className: 'danger'
    }
};

export const SUPPLIER_STATES = {
    DRAFT: {
        stateId: 0,
        name: '_draft_'
    },
    SENDED: {
        stateId: 6,
        name: '_sended_'
    },
    CONFIRMED: {
        stateId: 1,
        name: '_accepted_'
    },
    SENT_TO_PARTNER: {
        stateId: 3,
        name: '_sent_to_partner_'
    },
    REJECTED: {
        stateId: 2,
        name: '_rejected_'
    },
    READY_TO_DELIVERY: {
        stateId: 4,
        name: '_ready_to_delivery_'
    },
	stateById(id) {
	    switch (id) {
            case SUPPLIER_STATES.CONFIRMED.stateId:
                return SUPPLIER_STATES.CONFIRMED;
                case SUPPLIER_STATES.REJECTED.stateId:
                    return SUPPLIER_STATES.REJECTED;
                case SUPPLIER_STATES.SENT_TO_PARTNER.stateId:
                    return SUPPLIER_STATES.SENT_TO_PARTNER;
                case SUPPLIER_STATES.READY_TO_DELIVERY.stateId:
                    return SUPPLIER_STATES.READY_TO_DELIVERY;
            default:
                return SUPPLIER_STATES.DRAFT;
	    }
	},
};

export const USER_ROLES = [
    {
        name: '_call_center_',
        id: 4
    },
    {
        name: '_administrator_',
        id: 6
    },
    {
        name: '_order_manager_',
        id: 5
    },
    {
        name: '_distributor_manager_',
        id: 2
    },
    {
        name: '_supplier_',
        id: 10
    }
];

export const USER_ROLE_IDS = {
    SUPPLIER: 10,
    ADMIN: 6,
    ORDER_MANAGER: 5,
    CALL_CENTER: 4,
    DIST_MANAGER: 2
}

export const MIN_ETA = {
    ORDER: 20,
    SUPPLIER: 10
}


