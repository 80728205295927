import Login from '../components/Login/templates/LoginForm';
import Orders from '../components/Order/templates/Orders';
import OrderDetails from '../components/Order/templates/OrderDetails';
import { Cms } from './cms';

export const ROUTS = [
    {
        path: '/order',
        exact: true,
        component: Orders,
        shouldBeWrapped: true,
        componentOptions: {
            allowAuthorized: true
        },
        name: 'orders'
    },
    {
        path: '/order/:id',
        exact: true,
        component: OrderDetails,
        shouldBeWrapped: true,
        componentOptions: {
            allowAuthorized: true
        },
        name: 'orders'
    },
    {
        path: '/login/',
        exact: true,
        component: Login,
        shouldBeWrapped: true,
        componentOptions: {
            allowUnauthorized: true,
        },
        name: 'login'
    },
    {
        path: '/',
        exact: false,
        component: Cms,
        shouldBeWrapped: false,
        name: 'root'
    }
];