import security from './security';
import {
    logout
} from '../../components/Login/login.actions';

export default (store) => {
    return function dispatchIfIsAuthorized(action) {
        if (!action.shouldNotCheckAuthentication &&!security.isAuthorized() && security.getToken()) {
            return logout(()=>{
                window.location.replace('/');
            });
        }
        return action;
    };
};