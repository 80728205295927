import  { LOGIN_ACTION_TYPES } from '../../config/constants';

export default function login(state = {}, action) {
	switch (action.type) {
		case LOGIN_ACTION_TYPES.LOGIN_SUCCESS:
			return {
				...state,
				isAuthorized: true,
				token: action.token
			};
		case LOGIN_ACTION_TYPES.LOGOUT_SUCCESS:
			let newState = Object.assign({}, state);
			newState.isAuthorized = false;
			delete newState.token;
			return newState;
		default:
			return state;
	}
}