import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { reducer as toastrReducer } from 'react-redux-toastr'

import login from '../components/Login/login.reducers';
import orders from '../components/Order/orders.reducers';
import orderDetails from '../components/Order/orderDetails.reducers';
import dataLoader from '../components/DataLoader/dataLoader.reducers';

export default combineReducers({
    login,
    orders,
    orderDetails,
    dataLoader,
    i18n: i18nReducer,
    toastr: toastrReducer,
});
