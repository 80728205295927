import { BASE_SOCKET_SERVER } from '../../config';
import Session from './session';

const _store = {};

class SessionManager {
    createSession(id, host = BASE_SOCKET_SERVER, options, connectCallback) {
        let session = this.getSession(id);
        if (!session) {
            session = new Session(host +id, id, options);
            _store[id] = session;
            session.connect(connectCallback);
        }
        return session;
    }

    getSession(id) {
        return _store[id] || null;
    }

    deleteSession(id) {
        delete _store[id];
    }
}

const sessionManager = new SessionManager();

export default sessionManager;
