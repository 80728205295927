import axios from 'axios';

import jwtHelper from '../helpers/jwt/jwtHelper';
import Notifier from '../helpers/message/Notifier';
import { CONNECTION_ERRORS } from './constants';

axios.interceptors.response.use(null, jwtHelper.getErrorResponseInterceptor());
axios.interceptors.response.use(null, function (error) {
	if (error.response && error.response.data && error.response.data.messages) {
		error.response.data.messages.forEach(function(message) {
			Notifier[message.severity](message.text);
		});
	} else if (error.message === CONNECTION_ERRORS.network_error.error) {
		Notifier.error(CONNECTION_ERRORS.network_error.message, CONNECTION_ERRORS.network_error.title, true);
	}
	return Promise.reject(error);
});
axios.interceptors.request.use(jwtHelper.getRequestInterceptor());
