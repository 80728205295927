export const columns = [
    {
        name: '_ean_',
        key: 'ean',
        className: 'text-left border-top-0 border-bottom-0',
        isKey: false
    },
    {
        name: '_name_',
        key: 'name',
        className: 'text-left border-top-0 border-bottom-0',
        isKey: false
    },
    {
        name: '_comment_',
        key: 'customerComment',
        className: 'text-left border-top-0 border-bottom-0',
        isKey: false
    },
    {
        name: '_price_',
        key: 'price',
        className: 'text-left border-top-0 border-bottom-0',
        isKey: false
    },
    {
        name: '_quantity_',
        key: 'quantity',
        className: 'text-right border-top-0 border-bottom-0',
        isKey: false
    },
    {
        name: '_suggested_quantity_',
        key: 'partnerQuantity',
        className: 'text-right border-top-0 border-bottom-0',
        isKey: false
    },
    {
        name: '_id_',
        key: 'code',
        className: 'text-left border-top-0 border-bottom-0',
        isKey: true
    }
]