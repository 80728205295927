export const convertEtaToHours = (eta) => {
    if (eta === null) {
        return '';
    }
    let hours = Math.abs(Math.trunc(eta / 60)),
        minutes = Math.abs(eta % 60),
        sign = (eta >= 0) ? '' : '-';
    if (hours <= 9) {
        hours = `0${hours}`;
    }
    if (minutes <= 9) {
        minutes = `0${minutes}`;
    }
    return `${sign}${hours}:${minutes}`;
};