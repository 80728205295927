import axios from 'axios';

import { BASE_URL } from '../../config';

import sessionManager from '../session/session.manager';
import security from '../security/security';
import Notifier from '../message/Notifier';

let events = [];

class Delivery {
    constructor() {
        this.sessionId = null;
        this.roomId = null;
        this.distributorListenersRegistered = false;
        this.ordersListenersRegistered = false;
        this.deliveredOrdersListenersRegistered = false;
        this.handleMessage = this.handleMessage.bind(this);
    }

    start(sessionId) {
        const _self = this;
        if (security.isAuthorized()) {
            if (sessionId) {
                return this.connectToSession(sessionId);
            }
            this.getSessionData(function() {
                return _self.connectToSession(_self.sessionId);
            });
        }
    }

    stop() {
        const session = this.getSession();
        if(session) {
            session.disconnect();
            sessionManager.deleteSession(session.id);
        }
        this.distributorListenersRegistered = false;
        this.ordersListenersRegistered = false;
        this.deliveredOrdersListenersRegistered = false;
    }

    getSessionData(callback) {
        const _self = this;
        axios.get(`${BASE_URL}auth/delivery`)
        .then(function(response) {
            _self.sessionId = response.data.sessionId;
            _self.roomId = response.data.roomId;
            callback(response.data);
        });
    }

    setSessionId(sessionId) {
        this.sessionId = sessionId;
    }

    setRoomId(roomId) {
        this.roomId = roomId;
    }

    deleteSessionId() {
        this.sessionId = null;
    }

    deleteRoomId() {
        this.roomId = null;
    }

    getSessionId() {
        return this.sessionId;
    }

    getRoomId() {
        return this.roomId;
    }

    getSession(sessionId) {
        sessionId = sessionId || this.getSessionId();
        return sessionManager.getSession(sessionId);
    }

    connect(sessionId) {
        sessionId = sessionId || this.getSessionId();
        const session = sessionManager.createSession(sessionId);
        this.session = session;
        return session;
    }

    connectToSession(sessionId) {
        sessionId = sessionId || this.getSessionId();
        let session = sessionManager.getSession(sessionId);
        if (!session) {
            session = this.connect(sessionId);
            session.once('connected', this.auth.bind(this));
            return session.once('connected', (data) => {
                if (data.connected) {
                    this.registerMessageHandler();
                }
            });
        }

        if (!this.session) {
            this.session = session;
        }

        if (!session.isAuthenticated) {
            return this.handleAuth();
        }

        this.join();
    }

    auth() {
        this.session.authenticate(undefined, this.handleAuth(this));
    }

    join(roomId) {
        roomId = roomId || this.getRoomId();
        this.session.join(roomId);
    }

    handleAuth() {
        const _self = this;
        this.session.once('authenticated', (data) => {
            if (data.authenticated) {
                _self.join();
                events.forEach((item) => {
                    this.session[item.type](item.event, item.cb);
                });
                events = [];
            }
        });
    }

    handleEvent(event, type, cb) {
        const session = this.getSession();
        if (session) {
            session[type](event, cb);
        } else {
            events.push({event, type, cb});
        }
    }

    initUser() {
        this.handleEvent('delivery:portalUserInit', 'emit');
    }

    handleMessage(message) {
			Notifier[message.severity](message.text);
    }

    registerMessageHandler(cb = this.handleMessage) {
        this.handleEvent('delivery:message', 'on', cb);
    }

    userJoined(cb) {
        this.handleEvent('portalUser:joined', 'once', cb);
    }

    userUpdated(cb) {
        this.handleEvent('portalUser:updated', 'on', cb);
    }

    orderAdded(cb) {
        this.handleEvent('order:added', 'on', cb);
    }

    orderFinished(cb) {
        this.handleEvent('order:finished', 'on', cb);
    }

    orderUpdated(cb) {
        this.handleEvent('order:updated', 'on', cb);
    }

    disconnected(cb) {
        this.handleEvent('disconnect', 'on', cb);
    }
}

export default new Delivery();
