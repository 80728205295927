import axios from 'axios';

import {
    BASE_URL,
} from '../../config';

import security from '../../helpers/security/security';
import delivery from '../../helpers/delivery/delivery';
import { unregisterServiceWorker } from "../../helpers/firebase/service"
import  { LOGIN_ACTION_TYPES } from '../../config/constants';

export const login = (user, cb) => {
    return (dispatch) => {
        axios.post(`${BASE_URL}auth/supplier-login`, {
                username: user.username,
                password: user.password
            })
            .then(function (response) {
                const payload = response.data;
                security.login(payload.token, payload);
                delivery.setSessionId(payload.sessionId);
                delivery.setRoomId(payload.roomId);
                delivery.start(payload.sessionId);
                dispatch({
                    type: LOGIN_ACTION_TYPES.LOGIN_SUCCESS,
                    token: payload.token
                });
                cb();
            });
    }
};

export const logout = (cb) => {
    return (dispatch) => {
        security.logout();
        delivery.stop();
        unregisterServiceWorker();
        if('function' === typeof dispatch){
            dispatch({
                type: LOGIN_ACTION_TYPES.LOGOUT_SUCCESS
            });
        }
        if ('function' === typeof cb) {
            cb();
        }
    };
};

export const registerSupplierToken = ( token) => {
    return () => {
        axios.post(`${BASE_URL}user/notification`, { token });
    }
};