
import React, { Component } from 'react';
import { Navbar, Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import security from '../../../helpers/security/security';
import { logout } from '../../Login/login.actions';
import translator from '../../../helpers/localization/translator';
import logo from '../../../assets/img/logout.png';

class Panel extends Component {
    renderLogoutButton = () => {
        if(!security.isAuthorized() && !this.props.login.isAuthorized) {
            return null;
        }
        const user = security.getUser();
        return (<div>
            <span>{user.name} |</span>
            <Button variant="light" className="m-2" onClick={()=>{
                this.props.logout(() => {
                    window.location.replace('/login');
                });
            }}>
                {translator.translate("_logout_")}
                <Image src={logo} className="buy-logout-icon" />
            </Button>
        </div>)
    }

    render() {
        return (<Navbar className="justify-content-end custom-nav bg-buy-main" fixed="top">
            {this.renderLogoutButton()}
        </Navbar>);
    }
};

const mapStateToProps = (state) => {
	return {
		login: state.login
	};
};

const NavPanel = connect(mapStateToProps, { logout })(Panel);

export { NavPanel }

