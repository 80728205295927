import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Container,
	Row,
	Col,
	Form,
	Table,
	Button,
	Image,
	Collapse,
	Card,
	ListGroup
} from 'react-bootstrap';
import { getAllOrders, updateProductByApi, readyToDelivery, inCollecting, withIce } from '../orders.actions';
import translator from '../../../helpers/localization/translator';
import { columns } from './productTableConf';
import ProductModal from './ProductModal';
import logo from '../../../assets/img/back-icon.png';
import { USER_ROLES, USER_ROLE_IDS, SUPPLIER_STATES } from '../../../config/constants';
import moment from 'moment'
class OrderDetails extends Component {
	constructor(props) {
		super(props);
		const order = this.getOrder() || {};
		this.state = {
			selectedProduct: null,
			inCollecting: order.inCollecting,
			withIce: order.withIce || false,
			collectedOrderAmount: order.collectedOrderAmount,
			supplierCanEdit: order.supplierCanEdit
		};
	}

	getOrder = () => {
		if(this.props.orderDetails.products) {
			return this.props.orderDetails;
		}
		return this.props.orders.find( order => String(order.orderId) === this.props.match.params.id);
	}

	selectProduct = (selectedProduct) => () => {
		const order  = this.getOrder();
		if(order.state === SUPPLIER_STATES.READY_TO_DELIVERY.stateId) {
			return;
		}
		this.setState({selectedProduct})
	}

	goHome = () => this.props.history.push('/order');

	closeProductModal = () => {
		this.setState({ selectedProduct: null });
	}

	getRoleName = (role) => {
		const roleData = USER_ROLES.find(user => user.id === role) || {};
		if(roleData.name) {
			return translator.translate(roleData.name);
		}
		return '';
	}

	onProductChange = (product) => {
		const order  = this.getOrder();
		const { partnerQuantity, message, _id} =  product;
		this.props.updateProductByApi(order._id, {_id, quantity: partnerQuantity, message});
		this.closeProductModal();
	}

	readyToDelivery = () => {		
		const order  = this.getOrder();
		const data = { collectedOrderAmount: this.state.collectedOrderAmount };
		this.props.readyToDelivery(order._id, data, ()=>{
			this.goHome();
		});
	}

	getDiscussion = (discussion) =>{
		return discussion.filter(item => !!item.message);
	}

	handleChange = (e) => {
		const order = this.getOrder();
		this.props.inCollecting(order._id, e.target.checked);
		this.setState({ inCollecting: e.target.checked });
	}


	handleWithIceChange = (e) => {
		const order = this.getOrder();
		this.props.withIce(order._id, e.target.checked);
		this.setState({ withIce: e.target.checked });
	}

	componentWillReceiveProps() {
		const order = this.getOrder();
		if (order) {
			this.setState({ collectedOrderAmount: order.collectedOrderAmount, supplierCanEdit: order.supplierCanEdit });
		}
	}

	render() {
		if(this.props.dataLoader.loading) {
			return null;
		}
		const order  = this.getOrder();
		if(!order || !order._id) {
			return this.goHome() || null;
		}
		if(!order.products) {
			return null;
		}
		return (<Container fluid className="bg-light">
			<Row className='page-content d-flex mt-5'>
				<Col xs={1} className='mt-5'>
					<Image src={logo} className="c-pointer buy-back-icon" onClick={this.goHome}/>
				</Col>
				<Col xs={2} className='mt-5'>
					{/* TODO use readyForDelivery button instead of this checkbox */}
					<Form.Group controlId="collecting">
						<Form.Check
							type="checkbox"
							checked={this.state.inCollecting || order.inCollecting}
							onChange={(e) => this.handleChange(e)}
							label={translator.translate('_collecting_')} />
						</Form.Group>
				</Col>
				<Col xs={2} className='mt-5'>
					{/* TODO use readyForDelivery button instead of this checkbox */}
					<Form.Group controlId="collecting">
						<Form.Check
							type="switch"
							id="custom-switch"
							checked={this.state.withIce || order.withIce}
							onChange={(e) => this.handleWithIceChange(e)}
							label={translator.translate('_with_ice_')} />
						</Form.Group>
				</Col>
			</Row>
			<Row className='page-content'>
				<Col xs={6} className='mt-3 d-flex'>
						{translator.translate('_orders_number_')}
					<div className='ml-2 font-weight-bolder'>
						{this.props.match.params.id}
					</div>
					<Form.Label className="ml-3  font-weight-normal">{translator.translate('_can_replace_products_')}
						<span className='text-danger font-weight-bolder'>{order.canReplaceProducts ? translator.translate('_yes_') : translator.translate('_no_')}</span>
					</Form.Label>
				</Col>
				<Col xs={3} className='mt-3 d-flex'>
					{translator.translate('_send_to_partner_date_')}
					<div className='ml-2 font-weight-bolder'>
						{moment(order.sendToPartnerDate).format('MM/DD/YYYY HH:mm')}
					</div>
				</Col>
				<Col xs={3} className='mt-3 d-flex'>
					{translator.translate('_ready_time_')}
					<div className='ml-2 font-weight-bolder'>
						{moment(order.readyTime).format('MM/DD/YYYY HH:mm')}
					</div>
				</Col>
			</Row>
			<Row className='page-content d-flex'>
				<Col xs={12} className='mt-3'>
    				<Form.Label>{translator.translate('_comments_')}</Form.Label>
					<Form.Control as="textarea" rows="3" disabled value={order.comments || ''}/>
				</Col>
			</Row>
			<Row className='page-content d-flex'>
				<Col xs={12} className='mt-3'>
				<Table className="mb-0">
					<thead>
						<tr>
						{columns.map(column => {
							return <th className={column.className} key={column.key}>{translator.translate(column.name)}</th>
						})}
						</tr>
					</thead>
					<tbody className="bg-white">
						{order.products.map(product => {
							const discussion = this.getDiscussion(product.discussion);
							const rowClass = !discussion.length ? "border-bottom border-solid" : '';
							const lastMessage = discussion[discussion.length -1] || {};
							const rowBgClass = (lastMessage.role === USER_ROLE_IDS.SUPPLIER) ? 'bg-success' : 'bg-warning'
							return (<React.Fragment  key={product._id}>
								<tr onClick={this.selectProduct(product)} className={`${rowClass} c-pointer`}>
									{columns.map(column => {
										let value = product[column.key];
										if(column.key === 'name' && product.configuration.length){
											product.configuration.forEach(config => {
												value = `${value}, ${config.groupName}`;
											});
										}
										return <td className={column.className} key={column.key}>{value}</td>
									})}
								</tr>
								{!!discussion.length && <tr>
									<td colSpan={columns.length} className="border-bottom border-top-0 border-solid p-0">
										<Card>
  											<Card.Header className={`${rowBgClass} c-pointer`} aria-controls={product._id}
												aria-expanded={this.state[product._id]}
												onClick={() => {
													this.setState({ [product._id]: !this.state[product._id] })}}>
													<span>{this.getRoleName(lastMessage.role)} :  {lastMessage.message}</span>
											</Card.Header>
											<Collapse in={this.state[product._id]}>
												<ListGroup variant="flush" id={product._id}>
													{discussion.map(item => {
														const rowClass = (item.role === USER_ROLE_IDS.SUPPLIER) ? 'bg-success' : 'bg-warning'
														return <ListGroup.Item key={item._id} className={rowClass}>
															<span>{this.getRoleName(item.role)} :  {item.message}</span>
														</ListGroup.Item>
													})}
												</ListGroup>
											</Collapse>
										</Card>
									</td>
								</tr>}
							</React.Fragment>);
						})}
					</tbody>
				</Table>
				</Col>
			</Row>
			<Row className='page-content'>
				<Col xs={12}>
					<Col className="bg-white pt-4 pb-4">
						<Row>
							<Col xs={8} className='d-flex'>
								{translator.translate('_payable_amount_')} :
							</Col>
							<Col xs={4} className='d-flex justify-content-end bg-white'>
								{order.payableAmount}
							</Col>
						</Row>
					</Col>
				</Col>
			</Row>
			<Row className='page-content'>
				<Col xs={12}>
					<Col className="bg-white pb-4">
						<Row>
							<Col xs={8} className='d-flex'>
								{translator.translate('_total_price_')} :
							</Col>
							<Col xs={4} className='d-flex justify-content-end bg-white'>
								{order.price}
							</Col>
						</Row>
					</Col>
				</Col>
			</Row>
			<Row className='page-content'>
				<Col xs={12}>
					<Col className="bg-white pb-4">
						<Row>
							<Col xs={8} md={11} className='d-flex'>
								{translator.translate('_collected_order_amount_')} :
							</Col>
							<Col xs={4} md={1} className='d-flex justify-content-end bg-white'>
								<Form.Control
									disabled={!order.supplierCanEdit}
									type="number"
									className="font-weight-light"
									onChange={(e) => this.setState({ collectedOrderAmount: e.target.value })}
									value={this.state.collectedOrderAmount} />
							</Col>
						</Row>
					</Col>
				</Col>
			</Row>
			<Row className='page-content d-flex'>
				<Col xs={12} className='mt-3 border-top-1 border-bottom-1'>
					<Form.Group>
						<Button
							type="submit"
							className="btn-buy-primary"
							onClick={this.readyToDelivery}
							block
							disabled={!this.state.supplierCanEdit || !this.state.collectedOrderAmount || this.state.collectedOrderAmount <= 0}>
							{translator.translate("_ready_to_delivery_")}
						</Button>
					</Form.Group>
				</Col>
			</Row>
			{!! this.state.selectedProduct && <ProductModal show={true}
				product={this.state.selectedProduct}
				onCancel={this.closeProductModal}
				onSave={this.onProductChange}
				onHide={this.closeProductModal}
			/>}
		</Container>);
	}
};

let mapStateToProps = (state) => {
	return {
		orders: state.orders,
		orderDetails: state.orderDetails,
		dataLoader: state.dataLoader
	};
};

export default connect(mapStateToProps, { getAllOrders, updateProductByApi, readyToDelivery, inCollecting, withIce })(OrderDetails);
