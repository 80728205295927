import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ROUTS } from './routs';
import { withPermissionControl } from './cms';
import Message from '../helpers/message/template/Message';
import { NavPanel } from '../components/Navbar/templates/Navbar';
import DataLoader from '../components/DataLoader/DataLoader';

class App extends Component {
    wrapComponent(rout) {
        if (rout.shouldBeWrapped) {
            return withPermissionControl(rout.component, rout.componentOptions);
        }
        return rout.component;
    }

    render() {
        return (<Router>
            <DataLoader />
            <NavPanel />
            <Switch>
                {ROUTS.map(rout => {
                    return <Route key={rout.name} {...rout} component={this.wrapComponent(rout)} />
                })}
            </Switch>
            <Message />
        </Router>);
    }
}

export default App;
